<template>
  <div class="sidebar-talkCode">
    <van-cell v-if="!onePro" @click="show = true" size="large" is-link center>
      <template #title>
        <span class="custom-title">项目：</span>
        <span class="pro-name">{{ projectName }}</span>
      </template>
    </van-cell>
    <div class="content" v-if="noData && loading">
      <div class="person-item">
        <div class="head">
          <img :src="clueInfo.avatar || require('../../../assets/images/default_head_icon.png')" alt="">
        </div>
        <div class="person-info">
          <strong>{{clueInfo.nickName}}</strong>
          <span>{{clueInfo.phone || '-'}}</span>
        </div>
      </div>
      <div class="info-item" v-if="codeList.length <= 0">
        <div class="title">
          <div class="title-left">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#iconwode_tuokema"></use>
            </svg>
            <span style="margin-left: 7px">专属二维码</span>
          </div>
        </div>
        <div class="showCode">
          <img src="../../../assets/images/noStore.png" alt="">
          <p class="tips">暂无专属二维码</p>
          <p class="add" @click="add">为该潜客生成二维码</p>
        </div>
      </div>
      <div class="info-item" v-if="codeList.length > 0">
        <div class="title">
          <div class="title-left" @click="codeListShow=true">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#iconwode_tuokema"></use>
            </svg>
            <span style="margin-left: 7px">{{codeList[codeIndex].qrName}}</span>
            <svg class="icon" aria-hidden="true" style="width:8px;height:8px;margin-left:6px">
              <use xlink:href="#iconxialajiantou"></use>
            </svg>
          </div>
          <div class="title-right">
            <span @click="add">新增</span>
            <em></em>
            <span @click="edit(codeList[codeIndex].tkId)">编辑</span>
          </div>
        </div>
        <div class="showCode">
          <img :src="codeList[codeIndex].qrUrl" alt="">
        </div>
      </div>
      <div class="info-item" v-if="codeList.length > 0">
        <div class="title">配置信息</div>
        <div class="info-detail">
          <div class="detail-item">
            <span>打标签：</span>
            <div class="detail-right">
              <span style="color: #303133;" v-if="resultSelectTagList && resultSelectTagList.length===0">-</span>
              <span v-else
                  class="tag"
                  v-for="item of resultSelectTagList"
                  :key="item.tagId"
              >{{ item.tagName }}</span
              >
              <span class="tag" v-if="selectTagList.length > 3">...</span>
            </div>
          </div>
          <div class="detail-item">
            <span>来源信息：</span>
            <div class="detail-right">
              {{codeList[codeIndex].channelName||'-'}}
            </div>
          </div>
          <div class="detail-item">
            <span>欢迎语：</span>
            <div class="detail-right">
              {{codeList[codeIndex].greeting?'已设置':'未设置'}}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="wxb-btn wxb-btn-fix" v-if="noData && codeList.length>0 && loading">
      <div class="cancel-btn" @click="showData">查看邀请数据</div>
      <div class="confirm-btn" @click="sendMsg" >发送专属二维码</div>
    </div>
    <div class="noData" v-if="!noData && loading">
      <img src="../../../assets/images/nofollowUp.png" alt="">
      <h6>该潜客在此项目下不归属于您</h6>
      <p>暂时不能为TA生成专属二维码</p>
    </div>
    
    <!--    项目弹窗-->
    <van-action-sheet v-model="show" :actions="actions" cancel-text="取消" close-on-click-action get-container="#app" @select="selectProj" />
    <!-- 二维码多个 -->
    <van-action-sheet v-model="codeListShow" :actions="codeListActions" cancel-text="取消" close-on-click-action get-container="#app" @select="selectCode" />
    <!-- 编辑/新增 拓客码 -->
    <editTalkCode 
      :clueInfo="clueInfo" 
      :sidebar="true" 
      :estateId="estateId"
      ref="editTalkCodeShow" 
      @editTalkCodeClose="editTalkCodeClose" 
      @refreshPage="refreshPage">
    </editTalkCode> 
    <!-- 拓客明细 -->
    <talkCodeDetail  :tkId="tkId" :talkCodeDetailShow = "talkCodeDetailShow" @talkCodeDetailClose="talkCodeDetailClose"></talkCodeDetail>  
  </div>
</template>

<script>
// import { wxAgentConfig } from '../../../util/util';
import sidebar from '@/api/sidebar';
import clue from '@/api/clue';
import mine from '@/api/mine';
import editTalkCode from '../../mine/components/editTalkCode.vue';
import talkCodeDetail from '../../mine/components/talkCodeDetail.vue';
import { Toast } from 'vant';
import { recursionGet, wxAgentConfig } from '@/util/util';
export default {
  name: 'sidebar-talkCode',
  components: { editTalkCode, talkCodeDetail },
  computed: {
    //标签展示
    resultSelectTagList() {
      return this.selectTagList.slice(0, 3); // selectTagList 原始数据
    }
  },
  data() {
    return {
      actions: [],
      projectName: '',
      onePro: false,
      show: false,
      noData: true, //潜客归属人
      loading: false,
      clueId: '',
      clueInfo: {},
      settingInfo: {}, //配置信息
      codeListShow: false,
      codeListActions: [],
      codeList: [],
      codeIndex: 0,
      talkCodeDetailShow: false,
      tkId: '',
      qrName: '',
      tagList: [],
      selectTagList: [],
      estateId: ''
    };
  },
  async mounted() {
    wxAgentConfig(this.cb, this, ['sendChatMessage']);
    this.opId = localStorage.getItem('platform_opId');
    await this.getSidebarEstates();
    await this.playTag();
    await this.getClueInfo();
  },
  methods: {
    cb() {
      console.log('cb');
    },
    async refreshPage() {
      let resList = await this.getCodeInfoList();
        this.codeListActions = resList.map((item, index) => {
          item.name = item.qrName;
          if (index === this.codeIndex) {
            item.color = '#3A74F2';
          } else {
            item.color = '';
          }
          return item;
        });
    },
    //查询拓客码
    async getCodeInfoList() {
      let { data } = await mine.getCodeInfoList({estateId: localStorage.getItem('platform_sidebarEstateId'), clueId: this.clueId});
      this.codeList = data;
      if (data.length > 0) {
        this.selectTagList = [];
        recursionGet(this.codeList[this.codeIndex].tagIds, this.tagList, this.selectTagList, 'childrenTags', 'tagId');
      }
      return new Promise((resolve) => {
        resolve(data);
      });
    },
    //切换二维码
    async selectCode(val, index) {
      this.selectTagList = [];
      this.qrName = val.name;
      this.tkId = val.tkId;
      this.codeIndex = index;
      this.codeListActions = this.codeListActions.map((item) => {
        if (item.tkId === val.tkId) {
          item.color = '#3A74F2';
        } else {
          item.color = '';
        }
        return item;
      });
      await this.playTag();
      await this.getCodeInfoList();
    },
    //编辑拓客码
    edit(tkId) {
      if (!tkId) { return; }
      this.$refs.editTalkCodeShow.init(tkId);
    },
    //新增拓客码
    add() {
      this.$refs.editTalkCodeShow.init();
    },
    //获取标签树platform_estateId
    async playTag() {
      let { code, data } = await mine.getTagTree({ opId: localStorage.getItem('platform_opId'), estateId: localStorage.getItem('platform_sidebarEstateId'), tagTypes: [1, 2].toString() });
      if (code === '0') {
        this.tagList = data;
      }
    },
    //获取潜客信息
    async getClueInfo() {
      this.loading = false;
      let params = {
        qwExternalUserid: localStorage.getItem('platform_qwExternalUserid') || 'wmUTwgCAAAVQWnGAInH0UgUuy71Zw5vA',
        estateId: localStorage.getItem('platform_sidebarEstateId'),
        opId: localStorage.getItem('platform_opId')
      };
      let { data } = await sidebar.sidebarBelongClue(params);
      this.noData = data.clueBelongFlag ? true : false;
      if (data.clueBelongFlag) {
        this.clueId = data.clueId;
        let res = await clue.getClueInfo({clueId: data.clueId, estateId: localStorage.getItem('platform_sidebarEstateId')});
        if (res.code === '0') {
          this.clueInfo = res.data;
          let resList = await this.getCodeInfoList();
          this.codeListActions = resList.map((item, index) => {
            item.name = item.qrName;
            if (index === 0) {
              item.color = '#3A74F2';
              this.qrName = item.qrName;
              this.tkId = item.tkId;
            } else {
              item.color = '';
            }
            return item;
          });
          this.loading = true;
        } else {
          this.loading = true;
        }
      } else {
        this.loading = true;
      }
    },
    //
    //选择项目
    async selectProj(val) {
      localStorage.setItem('platform_sidebarEstateId', val.estateId);
      this.projectName = val.estateShowName;
      this.estateId = val.estateId;
      this.actions = this.actions.map((item) => {
        if (item.estateId === val.estateId) {
          item.color = '#3A74F2';
        } else {
          item.color = '';
        }
        return item;
      });
      await this.getClueInfo();
    },
    //获取项目
    async getSidebarEstates() {
      let { data } = await sidebar.getSidebarEstates({ opId: this.opId });
      if (!data) return;
      if (data.length >= 2) {
        this.onePro = false;
      }
      if (!localStorage.getItem('platform_sidebarEstateId')) {
        localStorage.setItem('platform_sidebarEstateId', data[0].estateId);
      }
      this.actions = data.map((item) => {
        item.name = item.estateShowName;
        if (item.estateId === localStorage.getItem('platform_sidebarEstateId')) {
          item.color = '#3A74F2';
          this.projectName = item.estateShowName;
          this.estateId = item.estateId;
        } else {
          item.color = '';
        }
        return item;
      });
    },
    showData() {
      this.tkId = this.codeList[this.codeIndex].tkId;
      this.talkCodeDetailShow = true; 
    },
    talkCodeDetailClose() {
      this.talkCodeDetailShow = false;
    },
    sendMsg() {
      let form = new FormData();
      form.append('opId', localStorage.getItem('platform_opId') || '');
      form.append('weChatType', 0);
      form.append('posterUrl', this.codeList[this.codeIndex].qrUrl); //资源的url
      mine.uploadContentFile(form)
        .then((e) => {
          if (e.code=='0') {
            this.$wx.invoke('sendChatMessage', {
              msgtype: 'image',
              enterChat: false,
              image:
                {
                  mediaid: e.data.mediaId, //#图片的素材id
                },
            }, function(res) {
              if (res.err_msg == 'sendChatMessage:ok') {
                //发送成功
                Toast('发送成功');
              }
            });
          }
        });
    },
    editTalkCodeClose() {
      this.editTalkCodeShow = false;
    }
  }
};
</script>

<style lang="less" scoped>
.sidebar-talkCode{
  background: #f5f6f7;
  .content{
    padding: 12px 12px 67px 12px;
  }
  .noData{
    text-align: center;
    margin-top: 20%;
    img{
      width: 187px;
    }
    h6{
      font-size: 14px;
      font-weight: 500;
      color: #606266;
      line-height: 20px;
      margin: 0;
    }
    p{
      margin:2px 0 0;
      font-size: 12px;
      font-weight: 400;
      color: #909399;
      line-height: 18px;
    }
  }
  .person-item{
    display: flex;
    align-items: center;
    background: #FFFFFF;
    border-radius: 4px;
    padding: 12px;
    .head{
      width: 48px;
      height: 48px;
      border-radius: 8px;
      border: 1px solid #EBEEF5;
      overflow: hidden;
      margin-right: 12px;
      img{
        width: 48px;
      }
    }
    .person-info{
      display: flex;
      flex-direction: column;
      flex: 1;
      strong{
        margin-top: 3px;
        font-size: 15px;
        font-weight: 500;
        color: #303133;
        line-height: 18px;
      }
      span{
        font-size: 12px;
        font-weight: 400;
        color: #606266;
        line-height: 14px;
        margin-top: 4px;
      }  
    }
  }
  .info-item{
    background: #FFFFFF;
    border-radius: 4px;
    padding: 0 12px;
    margin-top: 12px;
    .showCode{
      overflow: hidden;
      text-align: center;
      padding: 24px 0;
      p{
        margin: 0;
      }
      .tips{
        margin: 0;
        color: #909399;
      }
      .add{
        width: 100%;
        height: 44px;
        line-height: 44px;
        background: #3A74F2;
        border-radius: 6px;
        text-align: center;
        color: #fff;
        font-size: 17px;
        margin-top: 24px;
      }
      img{
        width: 212px;
      }
    }
    .title{
      padding: 10px 0;
      font-size: 14px;
      font-weight: 500;
      color: #303133;
      border-bottom: 1px solid #ebeef5;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .title-left{
        display: flex;
        align-items: center;
        flex: 1;
        margin-right: 8px;
      }
      .title-right{
        span{
          font-size: 14px;
          font-weight: 400;
          color: #3A74F2;
          line-height: 16px;
        }
        em{
          display: inline-block;
          height: 8px;
          width: 1px;
          background: #EBEEF5;
          margin: 0 6px;
        }
      }
    }
    .info-detail{
      padding: 20px;
    }
    .detail-item{
      display: flex;
      margin-top: 8px;
      span{
        font-size: 14px;
        font-weight: 400;
        color: #909399;
        display: inline-block;
      }
      .detail-right{
        font-size: 14px;
        font-weight: 400;
        color: #303133;
      }
      .tag{
        border-radius: 2px;
        border: 1px solid rgba(58, 116, 242, 0.24);
        font-size: 12px;
        font-weight: 400; 
        color: rgba(58, 116, 242, 0.64);
        margin-right: 6px;
        padding: 1px 6px;
      }
    }

  }
}
</style>
